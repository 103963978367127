<template>
  <div
    ref="scroll"
    class="history theme-text-color"
    @scroll="handleScroll"
  >
    <div class="block">
      <h3>
        {{ $t('Search.lastSearches') }}
        <a
          v-if="searchHistory && searchHistory.length > 0"
          v-t="'Search.deleteAll'"
          href="#"
          @click="deleteAll"
        />
      </h3>
      <hr>
    </div>

    <template v-if="searchHistory">
      <div
        v-for="day in searchHistory"
        :key="day.date"
        class="block"
      >
        <h4 class="header">
          {{ formattedDate(day.date) }}
          <Button
            class="circle delete-all"
            @click="deleteSearchList(day.searches)"
          >
            <BaseIcon name="times" />
          </Button>
        </h4>
        <ul class="search-list">
          <li
            v-for="search in day.searches"
            :key="search.searchId"
            class="search-item"
          >
            <img
              class="search-icon"
              width="15"
              src="@/assets/icon_search.svg"
              alt="search icon"
            >
            <span @click="searchKeywords(search.keywords)">
              {{ search.keywords }}
            </span>
            <Button class="circle delete">
              <BaseIcon
                name="times"
                @click="deleteSearch(search.searchId)"
              />
            </Button>
          </li>
        </ul>
      </div>
      <div
        v-if="isScrollTopDisplayed"
        class="scroll-top light-button"
        @click="scrollToTop"
      >
        <img
          src="@assets/arrow_up_white.svg"
        >
      </div>
    </template>
    <h1
      v-else
      v-t="'Search.emptyHistory'"
      class="empty-label"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import BaseIcon from '@components/Base/BaseIcon'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'

dayjs.extend(isToday)
dayjs.extend(isYesterday)

export default {
  name: 'SearchHistory',
  components: { BaseIcon },
  data () {
    return {
      isScrollTopDisplayed: false
    }
  },
  computed: {
    searchHistory () {
      return this.$store.getters['search/sortedHistoryList']
    }
  },
  created () {
    this.$store.dispatch('search/initSearchHistory')
  },
  methods: {
    deleteAll () {
      this.$store.dispatch('search/deleteHistory')
    },
    deleteSearch (searchId) {
      this.$store.dispatch('search/deleteSearchEntries', [searchId])
    },
    deleteSearchList (searchList) {
      const searchIds = []
      searchList.forEach(searchEntry => searchIds.push(searchEntry.searchId))
      this.$store.dispatch('search/deleteSearchEntries', searchIds)
    },
    formattedDate (date) {
      const searchDate = dayjs(date)
      return searchDate.isToday() ? this.$t('AppCommonsLabels.today')
        : searchDate.isYesterday() ? this.$t('AppCommonsLabels.yesterday') : searchDate.format('DD MMMM YYYY')
    },
    handleScroll (e) {
      const scroll = e.target
      this.isScrollTopDisplayed = (scroll.scrollTop > 1000)
    },
    scrollToTop () {
      this.$refs.scroll.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    searchKeywords (keywords) {
      this.$router.push({ name: 'search', params: { query: encodeURIComponent(keywords) } })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@design';

.history {
  height: calc(100% - #{$desktop-search-bar-height});
  overflow-y: auto;
  background-color: $dark-white-bg;
}

.toto {
  .scroll-top {
    bottom: 60px;
    right: 15px;
  }
}

.scroll-top {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.block {
  width: 90%;
  padding: 0.6rem;
  margin: 1.5rem auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);

  hr {
    border-color: $color-menu-bg;
  }
}

h3 {
  display: flex;

  a {
    margin-left: auto;
    font-weight: normal;
    color: $like-comment;
    text-decoration: none;
  }
}

.header {
  display: flex;
  align-items: center;
  padding: 1rem 0.6rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: bold;
  background: #F3F6F8;
  border-radius: 5px;
}

.delete-all {
  margin-left: auto;
  color: white;
  background-color: $public-bg;
  border: none;
}

.search-list {
  margin: 0;
}

.search-item {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;

  span {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .delete {
    margin-right: 10px;
    margin-left: auto;
    border: none;
  }
}

.search-icon {
  margin-right: 0.3rem;
}

.empty-label {
  margin-top: 15%;
  text-align: center;
}
</style>
